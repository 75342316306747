import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core"

import { INPUT_MODEL, InputComponent, InputModel } from "@anzar/core"
import { InputMask } from "@anzar/core/src/form.module/input/input-mask.service"

@Component({
    selector: ".eur-amount-input",
    providers: [InputMask, ...INPUT_MODEL],
    templateUrl: "./amount-input.component.pug"
})
export class AmountInputComponent extends InputComponent<number> implements AfterViewInit {
    @ViewChild("input", { read: ElementRef, static: false }) public readonly input: ElementRef<HTMLInputElement>

    constructor(
        @Inject(ElementRef) private readonly el: ElementRef<HTMLInputElement>,
        @Inject(InputMask) private readonly mask: InputMask,
        @Inject(InputModel) model: InputModel<number>
    ) {
        super(model)
        this.monitorFocus(el.nativeElement)
    }

    ngAfterViewInit() {
        this.mask.connect(this.input.nativeElement, {
            mask: Number,
            scale: 2,
            thousandsSeparator: " ",
            padFractionalZeros: true,
            normalizeZeros: true,
            radix: ",",
            mapToRadix: [".", ","],
            autofix: true,
            min: -Infinity,
            max: Infinity
        })

        this.destruct.subscription(this.mask.accept).subscribe(value => {
            const amount = Number(value.unmaskedValue)
            this.model.emitValue(amount)
        })
    }

    protected _renderValue(value: number): void {
        const amount = String(value).replace(".", ",")
        const unmasked = String(this.mask.unmaskedValue).replace(".", ",")
        if (amount !== unmasked) {
            this.mask.value = amount
        }
    }
}
