import { Component, Inject, InjectionToken } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { BehaviorSubject } from "rxjs"

import { LayerRef } from "@anzar/core"

export const UPDATE_PROFORMA_DATA = new InjectionToken<object>("order.module/update-proforma-data")

@Component({
    selector: "eur-order-update-proforma",
    templateUrl: "./update-proforma.component.pug"
})
export class UpdateProformaComponent {
    readonly form = new FormGroup({
        proforma_need: new FormControl(),
        proforma_amount: new FormControl()
    })

    readonly busy = new BehaviorSubject(false)

    constructor(
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(UPDATE_PROFORMA_DATA) data: object
    ) {
        this.form.reset(data)
    }

    doSave() {
        this.layerRef.emit({ type: "save", data: this.form.value })
        this.layerRef.hide()
    }

    doClose() {
        this.layerRef.hide()
    }
}
