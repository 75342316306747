import { Inject, Injectable } from "@angular/core"

import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    merge,
    of,
    shareReplay,
    switchMap,
    take
} from "rxjs"

import { LoadFields, ObservableSource } from "@anzar/core"

import { ComplaintItem, ComplaintItemRepo, ShippingItem, ShippingItemRepo } from "@backend/order.api"

export const SHPPING_ITEM_FIELDS: LoadFields<ShippingItem> = [
    "id",
    "serial",
    "invoiced",
    "refunded",
    { order_item: ["name"] },
    {
        shipping: [{ supplier: ["name"] }, { complaint: ["serno"] }]
    }
]

export const COMPLAINT_ITEM_FIELDS: LoadFields<ComplaintItem> = ["id", "complaint_id", "shipping_item_id"]

@Injectable()
export class ComplaintProducts {
    public set orderId(val: number | null) {
        if (this._orderId.value !== val) {
            this._orderId.next(val)
        }
    }
    public get orderId(): number | null {
        return this._orderId.value
    }
    private _orderId = new BehaviorSubject<number | null>(null)

    private readonly _reload = new BehaviorSubject<void>(undefined)
    private readonly _reloadWOrderId = merge(
        this._orderId.pipe(distinctUntilChanged()),
        this._reload.pipe(
            // debounceTime(100),
            switchMap(() =>
                this._orderId.pipe(
                    filter(v => !!v),
                    take(1)
                )
            )
        )
    )

    public readonly all = this._reloadWOrderId.pipe(
        switchMap(orderId => {
            if (!orderId) {
                return of([] as ShippingItem[])
            } else {
                return this.shippingItemRepo.search(
                    {
                        filter: {
                            "shipping.order_id": orderId,
                            "shipping.delivery_status": { in: ["SUCCESS", "FAILURE", "RETURNED"] }
                        }
                    },
                    { loadFields: SHPPING_ITEM_FIELDS }
                )
            }
        }),
        shareReplay(1)
    )

    public readonly selected = this._reloadWOrderId.pipe(
        switchMap(orderId => {
            if (!orderId) {
                return of([] as ComplaintItem[])
            } else {
                return this.complaintItemRepo.search(
                    {
                        filter: {
                            "complaint.order_id": orderId
                        }
                    },
                    { loadFields: COMPLAINT_ITEM_FIELDS }
                )
            }
        }),
        shareReplay(1)
    )

    public readonly selectable = combineLatest({
        all: this.all,
        selected: this.selected.pipe(map(items => items.map(item => item.shipping_item_id)))
    }).pipe(
        map(({ all, selected }) => all.filter(item => item.invoiced && !selected.includes(item.id))),
        shareReplay(1)
    )

    public readonly allSrc = new ObservableSource(this.all)
    public readonly selectableSrc = new ObservableSource(this.selectable)

    public constructor(
        @Inject(ComplaintItemRepo) private readonly complaintItemRepo: ComplaintItemRepo,
        @Inject(ShippingItemRepo) private readonly shippingItemRepo: ShippingItemRepo
    ) {}

    public reload() {
        this._reload.next()
    }

    public selectableInComplaint(complaintId: number) {
        return combineLatest({
            all: this.all,
            selected: this.selected.pipe(map(items => items.map(item => item.shipping_item_id)))
        }).pipe(
            map(({ all, selected }) => all.filter(item => item.invoiced && !selected.includes(item.id))),
            shareReplay(1)
        )
    }
}
