import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"
import { FsModule } from "@pyzar/fs.module"
import { PyzarTaskModule, TaskDetailsFactory } from "@pyzar/task.module"

import { CarrierApi } from "@backend/carrier.api"
import { CommonApi } from "@backend/common.api"
import { OrderApi } from "@backend/order.api"
import { SupplierApi } from "@backend/supplier.api"
import { SupplierSorderApi } from "@backend/supplier.sorder.api"

import { AccountingModule } from "../accounting.module"
import { EuropeerCommonModule } from "../common.module"
import { ProductModule } from "../product.module"
import { ShippingModule } from "../shipping.module"
import { ComplaintFileComponent } from "./complaint/complaint-file.component"
import { ComplaintItemComponent } from "./complaint/complaint-item.component"
import { ComplaintNoteComponent } from "./complaint/complaint-note.component"
import { ComplaintComponent } from "./complaint/complaint.component"
import { NewComplaintComponent } from "./complaint/new-complaint.component"
import { StartShippingComponent } from "./complaint/start-shipping.component"
import { OrderCreditingService } from "./crediting/crediting.service"
import { CreditingFormComponent } from "./crediting/form.component"
import { CreditingGridComponent } from "./crediting/grid.component"
import { CreditingScreenComponent } from "./crediting/screen.component"
import { OrderCustomerFormComponent } from "./customer-form.component"
import { CancelComponent } from "./details/cancel.component"
import { CustomerInfoComponent } from "./details/customer-info.component"
import { OrderHistoryAddComponent } from "./details/history-add.component"
import { OrderHistoryItemComponent } from "./details/history-item.component"
import {
    ItemChangeSupplierComponent,
    ItemSupplyErrorComponent,
    ItemUpdateQtyComponent
} from "./details/item-action/item-action.component"
import { OrderExtraItemComponent, OrderItemComponent } from "./details/item.component"
import { OrderItemsComponent } from "./details/items.component"
import { OrderDetailsWndComponent } from "./details/order-details-wnd.component"
import { OrderDetailsComponent } from "./details/order-details.component"
import { OrderShippingComponent } from "./details/shipping.component"
import { OrderSupplyComponent } from "./details/supply.component"
import { UpdateProformaComponent } from "./details/update-proforma.component"
import { OrderExtraItemFormComponent } from "./extra-item-form.component"
import { InvoiceAndShippingTaskDetailsFactory } from "./invoice-shipping-details.factory"
import { OrderItemSupplierSelectComponent } from "./item-supplier-select.component"
import { OrderBadgeComponent } from "./order-badge.component"
import { OrderGridComponent } from "./order-grid.component"
import { OrderStatusIconComponent } from "./order-status-icon.component"
import { OrderScreenComponent } from "./order.screen"
import { OrderProductFormComponent } from "./product-form.component"
import { OrderWizardWndComponent } from "./wizard/order-wizard-wnd.component"
import { OrderWizardStepBasicsComponent } from "./wizard/step-basics.component"
import { OrderWizardStepExtraItemsComponent } from "./wizard/step-extra-items.component"
import { OrderWizardStepProductsComponent } from "./wizard/step-products.component"

export const ROUTES: Route[] = [
    { path: "orders", component: OrderScreenComponent },
    { path: "crediting", component: CreditingScreenComponent }
]

@NgModule({
    imports: [
        CommonModule,
        NzModule,
        FsModule,
        OrderApi,
        SupplierApi,
        SupplierSorderApi,
        CommonApi,
        EuropeerCommonModule,
        PyzarCommonModule,
        PyzarTaskModule,
        ProductModule,
        AccountingModule,
        ShippingModule,
        CarrierApi
    ],
    declarations: [
        OrderGridComponent,
        OrderScreenComponent,
        OrderStatusIconComponent,
        OrderDetailsWndComponent,
        OrderBadgeComponent,

        OrderDetailsComponent,
        OrderItemComponent,
        OrderExtraItemComponent,
        OrderItemsComponent,

        ItemUpdateQtyComponent,
        ItemChangeSupplierComponent,
        ItemSupplyErrorComponent,

        OrderHistoryItemComponent,
        CancelComponent,
        CustomerInfoComponent,
        OrderShippingComponent,
        ComplaintComponent,
        ComplaintItemComponent,
        ComplaintNoteComponent,
        ComplaintFileComponent,
        NewComplaintComponent,
        OrderSupplyComponent,
        OrderHistoryAddComponent,
        OrderItemSupplierSelectComponent,
        StartShippingComponent,
        UpdateProformaComponent,

        OrderCustomerFormComponent,
        OrderProductFormComponent,
        OrderExtraItemFormComponent,

        OrderWizardWndComponent,
        OrderWizardStepBasicsComponent,
        OrderWizardStepProductsComponent,
        OrderWizardStepExtraItemsComponent,

        CreditingScreenComponent,
        CreditingGridComponent,
        CreditingFormComponent
    ],
    exports: [
        OrderGridComponent,
        OrderScreenComponent,
        OrderStatusIconComponent,
        OrderDetailsWndComponent,
        OrderDetailsComponent,
        OrderBadgeComponent,
        OrderShippingComponent,
        NewComplaintComponent,
        OrderWizardWndComponent,
        CreditingScreenComponent
    ],
    providers: [
        OrderCreditingService,
        { provide: TaskDetailsFactory, useClass: InvoiceAndShippingTaskDetailsFactory, multi: true }
    ],
    entryComponents: [
        OrderDetailsWndComponent,
        CancelComponent,
        CreditingFormComponent,
        NewComplaintComponent,
        OrderWizardWndComponent,
        ItemUpdateQtyComponent,
        ItemChangeSupplierComponent,
        ItemSupplyErrorComponent,
        StartShippingComponent,
        UpdateProformaComponent
    ]
})
export class OrderModule {}
