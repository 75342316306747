import { coerceBooleanProperty } from "@angular/cdk/coercion"
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core"
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms"

import { combineLatest, distinctUntilChanged, filter, map, Observable, shareReplay, startWith } from "rxjs"

import {
    Destructible,
    Field,
    FocusGroup,
    INPUT_MODEL_VALUE_CMP,
    InputGroupModel,
    InputModel,
    Model,
    StaticSource
} from "@anzar/core"
import { InputMask } from "@anzar/core/src/form.module/input/input-mask.service"

import { VatRate } from "@backend/common.api"
import { Currency } from "@backend/enum.api"

export class PriceType extends Model {
    @Field({ primary: true }) public value: "net" | "gross"
    @Field() public label: string
}

export const PRICE_TYPES = new StaticSource(PriceType, [
    { value: "net", label: "Nettó" },
    { value: "gross", label: "Bruttó" }
])

export interface PriceInputValue {
    net?: number
    gross?: number
    vat_id: number
    currency: string
}

export interface PriceInputAmounts {
    net: number
    gross: number
    currency: string
    type: "net" | "gross"
}

function priceEqCmp(a: PriceInputValue, b: PriceInputValue) {
    return a.currency === b.currency && a.vat_id === b.vat_id && a.net === b.net && a.gross === b.gross
}

export class PriceInputModel extends InputGroupModel<PriceInputValue> {
    public get isEmpty(): boolean {
        const val = this.value
        return val == null || (val.net == null && val.gross == null) || val.currency == null || val.vat_id == null
    }
}

@Component({
    selector: "eur-price-input",
    templateUrl: "./price-input.component.pug",
    exportAs: "eurPriceInput",
    providers: [
        { provide: INPUT_MODEL_VALUE_CMP, useValue: priceEqCmp },
        { provide: InputGroupModel, useClass: PriceInputModel },
        { provide: InputModel, useExisting: InputGroupModel },
        { provide: FocusGroup, useClass: FocusGroup },
        { provide: InputMask, useClass: InputMask }
    ]
})
export class PriceInputComponent extends Destructible implements OnInit {
    @Input()
    public set hideVat(val: boolean) {
        val = coerceBooleanProperty(val)
        if (this._hideVat !== val) {
            this._hideVat = val
        }
    }
    public get hideVat(): boolean {
        return this._hideVat
    }
    private _hideVat: boolean = false

    @Input()
    public set hideCurrency(val: boolean) {
        val = coerceBooleanProperty(val)
        if (this._hideCurrency !== val) {
            this._hideCurrency = val
        }
    }
    public get hideCurrency(): boolean {
        return this._hideCurrency
    }
    private _hideCurrency: boolean = false

    @ViewChild("input", { read: ElementRef, static: false }) public readonly input: ElementRef<HTMLInputElement>

    static formModel(defaults?: Partial<PriceInputValue>, required?: string) {
        return new FormGroup(
            {
                net: new FormControl(defaults?.net),
                gross: new FormControl(defaults?.gross),
                vat_id: new FormControl(defaults?.vat_id),
                currency: new FormControl(defaults?.currency)
            },
            {
                validators: [
                    (control: AbstractControl): ValidationErrors | null => {
                        if (required != null) {
                            const net = control.get("net").value
                            const gross = control.get("gross").value
                            if (net == null && gross == null) {
                                return { required }
                            }
                        }
                        return null
                    }
                ]
            }
        )
    }

    public readonly priceTypeSrc = PRICE_TYPES
    public readonly currencySrc = Currency.DATA
    public readonly vatRateSrc = VatRate.DATA

    public readonly amount = new FormControl()
    public readonly type = new FormControl("gross")
    public readonly vatRate = new FormControl(1)
    public readonly currency = new FormControl("HUF")

    public values$ = combineLatest({
        amount: this.amount.valueChanges.pipe(
            startWith(null),
            map(() => this.amount.value)
        ),
        type: this.type.valueChanges.pipe(
            startWith(null),
            map(() => this.type.value)
        ),
        vatId: this.vatRate.valueChanges.pipe(
            startWith(null),
            map(() => this.vatRate.value)
        ),
        currency: this.currency.valueChanges.pipe(
            startWith(null),
            map(() => this.currency.value)
        )
    })

    public amounts$: Observable<PriceInputAmounts | undefined> = this.values$.pipe(
        map(values => {
            const vatRate = VatRate.DATA.data.find(v => v.value === values.vatId)
            if (!vatRate || values.amount == null) {
                return undefined
            }

            if (values.type === "net") {
                const amount = values.amount * (1.0 + vatRate.amount / 100.0)
                return { net: values.amount, gross: amount, type: values.type, currency: values.currency }
            } else {
                const amount = values.amount / (1.0 + vatRate.amount / 100.0)
                return { net: amount, gross: values.amount, type: values.type, currency: values.currency }
            }
        }),
        shareReplay(1)
    )

    public anotherAmount$ = this.amounts$.pipe(
        filter(v => !!v),
        map(values => {
            if (values.type === "net") {
                const other = PRICE_TYPES.data.find(v => v.value === "gross")
                return { amount: values.gross, label: other.label, currency: values.currency }
            } else {
                const other = PRICE_TYPES.data.find(v => v.value === "net")
                return { amount: values.net, label: other.label, currency: values.currency }
            }
        }),
        shareReplay(1)
    )

    private readonly emitValues$ = this.values$.pipe(
        map(values => {
            if (values.type === "net") {
                return {
                    net: values.amount,
                    gross: null,
                    currency: values.currency,
                    vat_id: values.vatId
                }
            } else {
                return {
                    net: null,
                    gross: values.amount,
                    currency: values.currency,
                    vat_id: values.vatId
                }
            }
        }),
        shareReplay(1)
    )

    public constructor(@Inject(InputGroupModel) protected readonly igm: InputGroupModel<PriceInputValue>) {
        super()

        this.destruct.subscription(this.emitValues$).subscribe(values => {
            if (igm.control) {
                igm.control.setValue(values)
            }
        })
    }

    ngOnInit(): void {
        this.destruct
            .subscription(this.igm.valueChanges)
            .pipe(
                startWith(null),
                map(() => this.igm.value),
                distinctUntilChanged(priceEqCmp)
            )
            .subscribe(values => {
                if (values.currency != null) {
                    this.currency.setValue(values.currency)
                }

                if (values.vat_id != null) {
                    this.vatRate.setValue(values.vat_id)
                }

                if (values.gross != null) {
                    this.amount.setValue(values.gross)
                    this.type.setValue("gross")
                } else {
                    this.amount.setValue(values.net)
                    this.type.setValue("net")
                }
            })
    }
}
