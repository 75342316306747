import { Component, Inject } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"

import { forkJoin, Observable, of, Subject } from "rxjs"
import { map, shareReplay, startWith, switchMap, take } from "rxjs/operators"

import { LoadFields, ToastService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { AdvertProduct, AdvertProductRepo } from "@backend/advert.api"
import { MerchantProduct, MerchantProductRepo } from "@backend/merchant.api"
import { Partner } from "@backend/partner.api"
import { ProductQueryLink, ProductQueryLinkRepo, ProductQueryRepo } from "@backend/partner.api"
import { Product, Stock, StockRepo } from "@backend/product.api"
import { Warehouse, WarehouseRepo, WarehouseStock } from "@backend/supplier.api"

import { PartnerService } from "../common.module"
import { ProductOfferWndService } from "./product-offer-wnd.component"

const STOCK_FIELDS: LoadFields<Stock> = [
    "partner_id",
    "available",
    "total",
    "reserved",
    "msrp",
    "wslp",
    {
        supplier_product: ["id", "partner_entity_id", "is_active", { category: ["name", "full_name"] }]
    }
]

const MERCHANT_PRODUCT_FIELDS: LoadFields<MerchantProduct> = [
    "partner_id",
    "partner_entity_id",
    "wslp",
    "msrp",
    "rtlp",
    "is_active",
    "prepare_error",
    "sync_errors",
    "inactive_reason",
    "available_qty",
    "url",
    { category: ["name", "full_name"] }
]

const ADVERT_PRODUCT_FIELDS: LoadFields<AdvertProduct> = [
    "partner_id",
    "partner_entity_id",
    "rtlp",
    "is_active",
    "prepare_error",
    "inactive_reason",
    "available_qty",
    "url"
]

const PQL_FIELDS: LoadFields<ProductQueryLink> = ["dst_partner_id", "is_exclude", "is_exclusive", "partner_entity_id"]

interface StockEntry {
    partner: Partner
    total: number
    shared: Stock
    stocks: Array<[Warehouse, WarehouseStock]>
    isInternal: boolean
}

@Component({
    selector: ".eur-product-basics",
    templateUrl: "./product-basics.component.pug",
    providers: [ProductOfferWndService]
})
export class ProductBasicsComponent {
    public readonly form = new FormGroup({
        id: new FormControl(),
        name: new FormControl(),
        manufacturer: new FormControl(),
        sku: new FormControl(),
        eans: new FormControl(),
        kind: new FormControl()
    })

    public readonly reload$ = new Subject<void>()

    public readonly canViewMerchants$ = this.authSvc.hasPermission("product.view-merchants")
    public readonly canViewSuppliers$ = this.authSvc.hasPermission("product.view-suppliers")
    public readonly canViewAdvertisers$ = this.authSvc.hasPermission("product.view-advertisers")

    public readonly stocks$: Observable<StockEntry[]> = this.reload$.pipe(
        startWith(null),
        switchMap(() =>
            this.filterPartner(this.partnerSvc.partnersByTrait("SupplierProductTrait"), this.canViewSuppliers$)
        ),
        switchMap(partnerIds => {
            if (partnerIds && partnerIds.length) {
                const filter: any = { product_id: this.product.id, partner_id: { in: partnerIds } }

                return forkJoin([
                    this.warehousRepo.info({ partner_ids: partnerIds, product_id: this.product.id }).pipe(take(1)),
                    this.stockRepo.search({ filter }, { loadFields: STOCK_FIELDS }),
                    this.partnerSvc.suppliers$.pipe(
                        take(1),
                        map(suppliers => {
                            if (partnerIds && partnerIds.length) {
                                return suppliers.filter(v => partnerIds.includes(v.id))
                            } else {
                                return suppliers
                            }
                        })
                    )
                ])
            } else {
                return of([[], []])
            }
        }),
        map(([stocks, combineds, suppliers]: [Array<[Warehouse, WarehouseStock]>, Stock[], Partner[]]) =>
            suppliers.map(supplier => {
                const sitems = stocks.filter(s => s[0].partner_id === supplier.id)
                let total = 0
                for (const v of sitems) {
                    if (v[0].is_orderable) {
                        total += v[1].available
                    }
                }
                const combined = combineds.find(v => v.partner_id === supplier.id)
                return {
                    partner: supplier,
                    total: total,
                    shared: combined,
                    stocks: sitems.map(v => {
                        if (v[1].product_id !== combined.supplier_product.id) {
                            v[0].name = `${v[0].name} (${v[1].product.partner_entity_id})`
                        }
                        return v
                    }),
                    isInternal: supplier.internal_id === "europeer"
                }
            })
        ),
        shareReplay(1)
    )

    public readonly merchants$ = this.reload$.pipe(
        startWith(null),
        switchMap(() =>
            this.filterPartner(this.partnerSvc.partnersByTrait("MerchantProductTrait"), this.canViewMerchants$)
        ),
        switchMap(partnerIds => {
            const filter: any = { product_id: this.product.id }
            const pqlFilter: any = { product_id: this.product.id }

            if (partnerIds) {
                filter["partner_id"] = { in: partnerIds }
                pqlFilter["dst_partner_id"] = { in: partnerIds }
            }

            return forkJoin([
                this.mpRepo.search({ filter }, { loadFields: MERCHANT_PRODUCT_FIELDS }),
                this.pqlRepo.search({ filter: pqlFilter }, { loadFields: PQL_FIELDS }),
                this.partnerSvc.merchants$.pipe(
                    take(1),
                    map(merchants => {
                        if (partnerIds) {
                            return merchants.filter(v => partnerIds.includes(v.id))
                        } else {
                            return merchants
                        }
                    })
                )
            ])
        }),
        map(([products, links, merchants]) =>
            merchants.map(merchant => {
                const product = products.find(p => p.partner_id === merchant.id)
                const link = links.find(l => l.dst_partner_id === merchant.id)
                return {
                    partner: merchant,
                    product: product,
                    has_error: product && !!(product.prepare_error?.length || product.sync_errors?.length),
                    link: link,
                    product_view_url: product
                        ? product.url ||
                          this.partnerSvc.productViewUrl(merchant, this.product.id, product.partner_entity_id)
                        : null
                }
            })
        ),
        shareReplay(1)
    )

    public readonly adverts$ = this.reload$.pipe(
        startWith(null),
        switchMap(() =>
            this.filterPartner(this.partnerSvc.partnersByTrait("AdvertProductTrait"), this.canViewAdvertisers$)
        ),
        switchMap(partnerIds => {
            const filter: any = { product_id: this.product.id }
            const pqlFilter: any = { product_id: this.product.id }

            if (partnerIds) {
                filter["partner_id"] = { in: partnerIds }
                pqlFilter["dst_partner_id"] = { in: partnerIds }
            }

            return forkJoin([
                this.apRepo.search({ filter }, { loadFields: ADVERT_PRODUCT_FIELDS }),
                this.pqlRepo.search({ filter: pqlFilter }, { loadFields: PQL_FIELDS }),
                this.partnerSvc.adverts$.pipe(
                    take(1),
                    map(adverts => {
                        if (partnerIds) {
                            return adverts.filter(v => partnerIds.includes(v.id))
                        } else {
                            return adverts
                        }
                    })
                )
            ])
        }),
        map(([products, links, adverts]) =>
            adverts.map(advert => {
                const product = products.find(p => p.partner_id === advert.id)
                const link = links.find(l => l.dst_partner_id === advert.id)
                return {
                    partner: advert,
                    product: product,
                    has_error: product && !!product.prepare_error?.length,
                    link: link,
                    product_view_url: product?.url
                }
            })
        ),
        shareReplay(1)
    )

    public constructor(
        @Inject(Product) public readonly product: Product,
        @Inject(StockRepo) private readonly stockRepo: StockRepo,
        @Inject(WarehouseRepo) private readonly warehousRepo: WarehouseRepo,
        @Inject(MerchantProductRepo) private readonly mpRepo: MerchantProductRepo,
        @Inject(AdvertProductRepo) private readonly apRepo: AdvertProductRepo,
        @Inject(ProductQueryRepo) private readonly pqRepo: ProductQueryRepo,
        @Inject(ProductQueryLinkRepo) private readonly pqlRepo: ProductQueryLinkRepo,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(ProductOfferWndService) private readonly prodOfferWnd: ProductOfferWndService
    ) {
        this.form.reset({
            id: product.id,
            name: product.name,
            manufacturer: product.manufacturer.name,
            sku: product.sku,
            eans: product.eans.join(" ; "),
            kind: product.kind.label
        })
    }

    public doAttachMerchantProduct(partnerId: number, partnerEntityId: string) {
        this.pqRepo
            .attach_product({
                dst_partner_id: partnerId,
                partner_entity_id: partnerEntityId,
                product_id: this.product.id
            })
            .pipe(this.toast.catchError())
            .subscribe(_ => {
                this.reload()
            })
    }

    public reload() {
        this.reload$.next()
    }

    private filterPartner(partners$: Observable<Partner[]>, perm: Observable<boolean>): Observable<number[] | null> {
        return perm.pipe(
            switchMap(hasPerm => {
                if (hasPerm) {
                    return partners$.pipe(
                        take(1),
                        map(partners => partners.map(v => v.id))
                    )
                } else {
                    return this.authSvc.currentUser$.pipe(
                        take(1),
                        switchMap(
                            user =>
                                partners$.pipe(
                                    take(1),
                                    map(partners => partners.map(p => p.id))
                                )
                            // return this.partnerSvc.partners$.pipe(take(1), map(partners => {
                            //     return partners.filter(partner => partner.user_id === user.id).map(p => p.id)
                            // }))
                        )
                    )
                }
            })
        )
    }

    public doShowOffer(supplierId: number) {
        console.log({ supplierId })
        this.prodOfferWnd
            .show({ supplier_id: supplierId, product_id: this.product.id })
            .subscribe(v => v && this.reload())
    }
}
