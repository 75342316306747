import { Component, Inject } from "@angular/core"
import { FormControl } from "@angular/forms"

import { finalize } from "rxjs"

import { LayerRef } from "@anzar/core"

import { Complaint, PartnerRepoSource } from "@backend/__anzar_rpc_output"

import { ComplaintService } from "./complaint.service"

@Component({
    selector: "eur-start-shipping",
    host: {
        "[style.width.px]": "300"
    },
    templateUrl: `./start-shipping.component.pug`
})
export class StartShippingComponent {
    readonly supplier = new FormControl()

    busy = false

    constructor(
        @Inject(PartnerRepoSource) public readonly partnerSrc: PartnerRepoSource,
        @Inject(Complaint) private readonly complaint: Complaint,
        @Inject(LayerRef) private readonly layerRef: LayerRef,
        @Inject(ComplaintService) private readonly svc: ComplaintService
    ) {}

    doClose() {
        this.layerRef.hide()
    }

    doShip() {
        this.busy = true
        this.svc
            .shipComplaint(this.supplier.value)
            .pipe(finalize(() => (this.busy = false)))
            .subscribe(res => {
                this.layerRef.emit({ type: "done", data: res })
            })
    }
}
