import { Inject, Injectable } from "@angular/core"

import { Observable, Subject } from "rxjs"
import { map, mergeMap, shareReplay, startWith } from "rxjs/operators"

import { LoadFields } from "@anzar/core"

import { ApiAccess } from "@backend/api.api"
import { PickupAddressTrait } from "@backend/carrier.api"
import { MerchantJointProductTrait, MerchantOrderTrait, MerchantProductTrait } from "@backend/merchant.api"
import { Order } from "@backend/order.api"
import { Partner, PartnerRepo, PartnerTrait, PartnerTraitInfo } from "@backend/partner.api"

// export type TraitType<T> = { new(): T, POLYMORPH_ID: string }
const PARTNER_FIELDS: LoadFields<Partner> = ["id", "internal_id", "name", "is_active", "sequence", "impls", "traits"]

export type TraitMapping = {
    MerchantOrderTrait: MerchantOrderTrait
    MerchantProductTrait: MerchantProductTrait
    MerchantJointProductTrait: MerchantJointProductTrait
    MerchantCategoryUploadTrait: PartnerTrait
    UploadPaymentsTrait: PartnerTrait
    ApiAccess: ApiAccess
    SupplierProductTrait: PartnerTrait
    CarrierTrait: PartnerTrait
    AccountingTrait: PartnerTrait
    AdvertProductTrait: PartnerTrait
    UnasApi: PartnerTrait
    SupplierOrderTrait: PartnerTrait
    PickupAddressTrait: PickupAddressTrait
    WebshippyCategoryTrait: PartnerTrait
    ShopifyApi: PartnerTrait
}

export type TraitType<T> = T extends keyof TraitMapping
    ? TraitMapping[T]
    : T extends { new (...args: any[]): infer R; POLYMORPH_ID: string }
      ? R
      : never

// export type TraitSelector<T> = T extends keyof TraitMapping
//     ? T
//     : T extends { new(...args: any[]): PartnerTrait, POLYMORPH_ID: string } ? T : never

export type TraitSelector = keyof TraitMapping | { new (...args: any[]): PartnerTrait; POLYMORPH_ID: string }

export const enum PartnerCardinality {
    Supplier = "supplier",
    Merchant = "merchant",
    Carrier = "carrier",
    Accounting = "accounting",
    Advert = "advert",
    Api = "api",
    Other = "other"
}

export const partnerCardinalityLabel = {
    [PartnerCardinality.Supplier]: "Forgalmazó",
    [PartnerCardinality.Merchant]: "Kereskedő",
    [PartnerCardinality.Carrier]: "Szállító",
    [PartnerCardinality.Accounting]: "Számlázó",
    [PartnerCardinality.Advert]: "Reklámozó",
    [PartnerCardinality.Api]: "API",
    [PartnerCardinality.Other]: "Egyéb"
}

// export type PartnerCardinality = "supplier" | "merchant" | "carrier"

@Injectable({ providedIn: "root" })
export class PartnerService {
    private readonly _reset$ = new Subject<void>()

    public readonly allPartners$: Observable<Partner[]> = this._reset$.pipe(
        startWith(null),
        mergeMap(_ => this.repo.search({ order: { sequence: "asc" } }, { loadFields: PARTNER_FIELDS })),
        shareReplay(1)
    )

    public readonly partners$: Observable<Partner[]> = this.allPartners$.pipe(
        map(partners => partners.filter(partner => partner.is_active)),
        shareReplay(1)
    )

    public partnersByTrait(...traits: TraitSelector[]): Observable<Partner[]> {
        const impls = traits.map(selectorAsString)
        return this.partners$.pipe(
            map(partners => partners.filter(p => p.impls.some(impl => impls.includes(impl)))),
            shareReplay(1)
        )
    }

    public readonly merchants$ = this.partnersByTrait("MerchantOrderTrait")
    public readonly suppliers$ = this.partnersByTrait("SupplierProductTrait")
    public readonly carriers$ = this.partnersByTrait("CarrierTrait")
    public readonly accountings$ = this.partnersByTrait("AccountingTrait")
    public readonly adverts$ = this.partnersByTrait("AdvertProductTrait")
    public readonly europeer$ = this.allPartners$.pipe(
        map(partners => partners.find(v => v.internal_id === "europeer"))
    )

    public constructor(@Inject(PartnerRepo) private readonly repo: PartnerRepo) {}

    public reset() {
        this._reset$.next()
    }

    public get(id: number): Observable<Partner> {
        return this.repo.get({ id }, { loadFields: PARTNER_FIELDS })
    }

    public hasTrait(partner: Partner, trait: TraitSelector): boolean {
        return partner.impls.includes(selectorAsString(trait))
    }

    public getTrait<T extends TraitSelector>(partner: Partner, trait: T): TraitType<T> | null {
        if (!partner.traits || partner.traits.length === 0) {
            return null
        }

        const find = traitDescendants(trait)
        for (const data of partner.traits) {
            if (find.includes(data.impl)) {
                return data as any
            }
        }
        return null
    }

    public getCardinality(partner: Partner): PartnerCardinality[] {
        const result: PartnerCardinality[] = []

        if (this.hasTrait(partner, "ApiAccess")) {
            result.push(PartnerCardinality.Api)
        }

        if (this.hasTrait(partner, "MerchantOrderTrait")) {
            result.push(PartnerCardinality.Merchant)
        }

        if (this.hasTrait(partner, "SupplierProductTrait")) {
            result.push(PartnerCardinality.Supplier)
        }

        if (this.hasTrait(partner, "CarrierTrait")) {
            result.push(PartnerCardinality.Carrier)
        }

        if (this.hasTrait(partner, "AccountingTrait")) {
            result.push(PartnerCardinality.Accounting)
        }

        if (this.hasTrait(partner, "AdvertProductTrait")) {
            result.push(PartnerCardinality.Advert)
        }

        if (result.length === 0) {
            result.push(PartnerCardinality.Other)
        }

        return result
    }

    public loadTrait<T extends TraitSelector>(partnerId: number, trait: T) {
        return this.repo.get_trait({
            partner_id: partnerId,
            trait: selectorAsString(trait)
        }) as Observable<TraitType<T> | null>
    }

    // public trackingUrls(partner: Partner, trackingNumbers: string[]): Observable<string | null> {
    //     return this.loadTrait(partner, CarrierTrait).pipe(
    //         map(carrier => {
    //             if (!carrier) {
    //                 return null
    //             }
    //             return carrier.tracking_url.replace(/\{\{tracking_numbers:(.*?)\}\}/, (g0, g1) => {
    //                 return trackingNumbers.join(g1)
    //             })
    //         })
    //     )
    // }

    // public trackingUrls(partner: Partner, trackingNumbers: string[]): Array<[string, string]> {
    //     let url = partner.tracking_url.replace(/\{\{tracking_numbers:(.*?)\}\}/, (g0, g1) => {
    //         return trackingNumbers.join(g1)
    //     })

    //     return [[trackingNumbers.join(", "), url]]
    // }

    // TODO: repo.order_view_url(partner_id, order_id)
    // TODO: repo.product_view_url(partner_id, product_id)

    public orderViewUrl(partner: Partner, order: Order): string | null {
        const vars: { [key: string]: any } = {
            source_id: order.partner_entity_id,
            source_orderno: order.partner_orderno,
            order_id: order.id
        }

        if (this.hasTrait(partner, "UnasApi")) {
            vars["source_id"] = vars["source_id"].split("-").pop()
        }

        if (this.hasTrait(partner, "ShopifyApi")) {
            vars["source_id"] = vars["source_id"].split("/").pop()
        }

        const trait = this.getTrait(partner, "MerchantOrderTrait")

        return trait && trait.order_view_url
            ? trait.order_view_url.replace(/\{\{\s*(.*?)\s*\}\}/g, (g0, g1) => vars[g1])
            : null
    }

    public productViewUrl(partner: Partner, productId: number, partnerEntityId: string): string | null {
        const vars: { [key: string]: any } = {
            source_id: partnerEntityId,
            product_id: productId
        }

        const trait = this.getTrait(partner, MerchantProductTrait)
        return trait && trait.product_view_url
            ? trait.product_view_url.replace(/\{\{\s*(.*?)\s*\}\}/g, (g0, g1) => vars[g1])
            : null
    }
}

function selectorAsString(trait: TraitSelector): string {
    if (typeof trait === "string") {
        return trait
    } else {
        return trait.POLYMORPH_ID
    }
}

function selectorAsInfo(trait: TraitSelector): PartnerTraitInfo {
    const impl = selectorAsString(trait)
    return PartnerTraitInfo.DATA.data.find(info => info.value === impl) as any
}

function traitDescendants(trait: TraitSelector): string[] {
    const begin = selectorAsInfo(trait)
    if (begin.value === "PartnerTrait") {
        return []
    }

    let result = [begin.value]
    for (const info of PartnerTraitInfo.DATA.data) {
        if (info.parents.includes(begin.value)) {
            result = result.concat(traitDescendants(info.value as TraitSelector))
        }
    }
    return result
}
