import { CommonModule } from "@angular/common"
import { InjectionToken, NgModule } from "@angular/core"

import { NzModule } from "@anzar/core"

import { PartnerApi } from "@backend/partner.api"

import { AmountInputComponent } from "./amount-input.component"
import { ChartCardComponent } from "./components/chart-card.component"
import { PartnerSwitchPanelComponent } from "./components/partner-switch/partner-switch-panel.component"
import { PartnerSwitchComponent, PartnerWithCardinality } from "./components/partner-switch/partner-switch.component"
import { PriceComponent } from "./components/price.component"
import { RatingComponent } from "./components/rating.component"
import { ImageService } from "./image.service"
import { PartnerCardinality, partnerCardinalityLabel, PartnerService } from "./partner.service"
import { PriceInputComponent } from "./price-input.component"
import { validateEqual, ValidateEqualDirective } from "./validate-equal.directive"

export { ImageService }

export { RpcErrorHandler } from "./rpc-interceptor"

export { validateEqual }

export { PartnerWithCardinality as SelectedPartner }

export { PartnerService, PartnerCardinality, partnerCardinalityLabel }

export { PriceInputComponent }

export { convertPrice, marginOf } from "./price"

export const BACKEND_BASE_URL = new InjectionToken("BACKEND_BASE_URL")

@NgModule({
    imports: [CommonModule, NzModule, PartnerApi],
    declarations: [
        RatingComponent,
        ValidateEqualDirective,
        PriceComponent,
        ChartCardComponent,
        PartnerSwitchComponent,
        PartnerSwitchPanelComponent,
        AmountInputComponent,
        PriceInputComponent
    ],
    exports: [
        RatingComponent,
        ValidateEqualDirective,
        PriceComponent,
        ChartCardComponent,
        PartnerSwitchComponent,
        PartnerSwitchPanelComponent,
        AmountInputComponent,
        PriceInputComponent
    ],
    providers: [ImageService, PartnerService]
})
export class EuropeerCommonModule {}
