import { Attribute, Component, EventEmitter, Inject, Input, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"

import { Observable, switchMap } from "rxjs"

import { DropdownLayer, LayerService, ToastService } from "@anzar/core"

import { PaymentMethod, PaymentStatus } from "@backend/enum.api"
import { BillingInfo, Order, OrderRepo, ShippingInfo } from "@backend/order.api"

import { customerFormModel } from "../customer-form.component"
import { UPDATE_PROFORMA_DATA, UpdateProformaComponent } from "./update-proforma.component"

@Component({
    selector: ".eur-customer-info",
    templateUrl: "./customer-info.component.pug"
})
export class CustomerInfoComponent {
    @Input() public order: Order
    @Input() public customer: ShippingInfo & BillingInfo
    @Input() public paymentStatus: PaymentStatus
    @Input() public hasProforma: boolean
    @Input() public merchantId: number

    @Input()
    public set editable(val: boolean) {
        if (this._editable !== val) {
            this._editable = val
            this.editableChange.next(val)

            if (val) {
                this.resetForm()
            }
        }
    }
    public get editable(): boolean {
        return this._editable
    }
    private _editable: boolean = false

    @Output() public readonly changes = new EventEmitter<any>()

    @Output() public readonly editableChange = new EventEmitter<boolean>()

    public readonly paymentMethodSrc = PaymentMethod.DATA

    public readonly form: FormGroup

    public constructor(
        @Attribute("type") public readonly type: "shipping" | "billing",
        @Inject(OrderRepo) public readonly orderRepo: OrderRepo,
        @Inject(ToastService) public readonly toast: ToastService,
        @Inject(LayerService) private readonly layerSvc: LayerService
    ) {
        this.form = customerFormModel(type === "billing")
    }

    public doCancelEdit() {
        this.editable = false
    }

    public doSave() {
        const sub = this.type === "billing" ? this._saveBilling() : this._saveShipping()

        sub.subscribe(changed => {
            this.editable = false
            if (changed) {
                this.changes.next(changed)
            }
        })
    }

    public mailtoLink(): string {
        let res: string = `mailto:${this.customer.emails.join(",")}`
        const subject = `Europeer#${this.order.id} rendelés változás`
        res += `?subject=${encodeURIComponent(subject)}`
        return res
    }

    private _saveBilling(): Observable<any> {
        const data = this._baseData()
        return this.orderRepo
            .patch({ data: { ref: { id: this.order.id }, billing_info: data } })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Számlázási adatok módosítása..." }))
    }

    private _saveShipping(): Observable<any> {
        const data = this._baseData()
        return this.orderRepo
            .patch({ data: { ref: { id: this.order.id }, shipping_info: data } })
            .pipe(this.toast.handleSave({ align: "bottom center", beginMsg: "Szállítási adatok módosítása..." }))
    }

    private _baseData(): { [key: string]: any } {
        const result = this.form.value

        if (result["emails"]) {
            result["emails"] = (result["emails"] as string).split(/\s*,\s*/g).filter(v => v.length)
        }

        if (result["phones"]) {
            result["phones"] = (result["phones"] as string).split(/\s*,\s*/g).filter(v => v.length)
        }

        return result
    }

    private resetForm() {
        this.form.reset({
            id: this.customer.id,
            name: this.customer.name,
            country: this.customer.country,
            city: this.customer.city,
            postcode: this.customer.postcode,
            shire: this.customer.shire,
            address: this.customer.address,
            phones: (this.customer.phones || []).join(", "),
            emails: (this.customer.emails || []).join(", "),
            note: this.customer.note,
            contact_name: this.customer.contact_name,
            tax_number: this.customer instanceof BillingInfo ? this.customer.tax_number : null,
            payment_method: this.customer instanceof BillingInfo ? this.customer.payment_method.value : null,
            proforma_need: this.customer instanceof BillingInfo ? this.customer.proforma_need : false,
            proforma_amount: this.customer instanceof BillingInfo ? this.customer.proforma_amount : false,
            fulfillment_days: this.customer instanceof BillingInfo ? this.customer.fulfillment_days : false,
            due_days: this.customer instanceof BillingInfo ? this.customer.due_days : false,
            price: this.customer instanceof ShippingInfo ? this.customer.price : null,
            shipping_method_id: this.customer instanceof ShippingInfo ? this.customer.shipping_method?.id : null
        })
    }

    public setPaid() {
        this.orderRepo.set_payment_status({ order_id: this.order.id, payment_status: "PAID" }).subscribe(changed => {
            if (changed) {
                this.changes.next(true)
            }
        })
    }

    public updateProforma(event: Event) {
        return new Observable(dst => {
            const behavior = new DropdownLayer({
                menuLike: true,
                backdrop: { type: "empty", hideOnClick: true },
                rounded: 3,
                elevation: 10,
                trapFocus: true,
                position: {
                    align: "top left",
                    anchor: {
                        ref: event.target as HTMLElement,
                        align: "bottom left",
                        margin: 8
                    }
                }
            })

            const layerRef = this.layerSvc.createFromComponent(UpdateProformaComponent, behavior, null, [
                {
                    provide: UPDATE_PROFORMA_DATA,
                    useValue: {
                        proforma_need: this.customer.proforma_need,
                        proforma_amount: this.customer.proforma_amount
                    }
                }
            ])
            layerRef.subscribe(event => {
                if (event.type === "hiding") {
                    dst.complete()
                } else if (event.type === "save") {
                    dst.next(event.data)
                    dst.complete()
                }
            })
            layerRef.show()

            return () => {
                layerRef.hide()
            }
        })
    }

    public doUpdateProforma(event: Event) {
        this.updateProforma(event)
            .pipe(
                switchMap((data: object) =>
                    this.orderRepo
                        .patch({
                            data: {
                                ref: { id: this.order.id },
                                billing_info: {
                                    id: this.customer.id,
                                    payment_method: this.customer.payment_method.value,
                                    ...data
                                }
                            }
                        })
                        .pipe(
                            this.toast.handleSave({
                                align: "bottom center",
                                beginMsg: "Számlázási adatok módosítása..."
                            })
                        )
                )
            )
            .subscribe(() => this.changes.next(true))
    }
}
